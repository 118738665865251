<template>
  <AuthLayout>
    <main>
      <div class="container">
        <div style="margin-top: 15vh" class="row justify-content-center">
          <div class="col-lg-5">
            <Profiles
              @set-role="(role) => (data.role = role)"
              v-if="data.role === ''"
            />

            <div v-else class="card shadow-lg border-0 rounded-lg">
              <div class="card-header justify-content-center">
                <img
                  class="mx-2"
                  style="width: 30px"
                  src="../assets/logo2.png"
                />
                <h3 class="font-weight-light my-4">CHI Marine Portal</h3>
              </div>
              <div class="card-body">
                <form @submit.prevent="authenticate(data)">
                  <!-- <div class="form-group">
                    <label class="small mb-1" for="inputRole"
                      >Select Role</label
                    >
                    <select
                      class="form-control"
                      v-model="data.role"
                      id="inputRole"
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="Admin">Admin</option>
                      <option value="Broker">Broker</option>
                      <option value="Agent">Agent</option>
                    </select>
                  </div> -->

                  <div class="form-group">
                    <label class="small mb-1" for="inputEmailAddress"
                      >Email</label
                    ><input
                      class="form-control py-4"
                      id="inputEmailAddress"
                      v-model="data.email"
                      type="email"
                      required
                      placeholder="Enter email address"
                      :disabled="loading"
                    />
                  </div>
                  <div class="form-group">
                    <label class="small mb-1" for="inputPassword"
                      >Password</label
                    ><input
                      class="form-control py-4"
                      id="inputPassword"
                      v-model="data.password"
                      type="password"
                      required
                      placeholder="Enter password"
                      :disabled="loading"
                    />
                  </div>

                  <div
                    class="form-group d-flex align-items-center justify-content-between mt-4 mb-0"
                  >
                    <router-link class="small" to="/"> Go Back </router-link>
                    <button v-if="loading" class="btn btn-primary disabled">
                      <div
                        class="spinner-border spinner-border-sm mx-1 text-light"
                        role="status"
                      ></div>
                      Loading...
                    </button>
                    <button v-else type="submit" class="btn btn-primary">
                      Login
                    </button>
                  </div>
                </form>
              </div>
              <div class="card-footer text-center">
                <div class="small">
                  <router-link class="" to="/changepassword"
                    >Change Password?</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AuthLayout>
</template>

<script>
import AuthLayout from "../layouts/AuthLayout.vue";
import Profiles from "../components/Profiles.vue";
import { mapState, mapActions } from "pinia";
import { useMainStore } from "../stores/main";
import { useAuthStore } from "../stores/authentication";

export default {
  name: "Login",
  components: {
    AuthLayout,
    Profiles,
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
        role: "",
      },
    };
  },
  computed: {
    ...mapState(useMainStore, ["isLoggedIn"]),
    ...mapState(useAuthStore, ["loading"]),
  },

  methods: {
    async authenticate(data) {
      const status = await this.login(data);
      if (status !== "success") {
        return;
      }
      await this.$router.push("/");
    },
    ...mapActions(useAuthStore, ["login"]),
  },

  created() {
    if (this.$route.params.admin == "admin") this.data.role = "Admin";
  },
};
</script>

<style></style>
