<template>
  <div class="card shadow-lg border-0 rounded-lg">
    <div class="card-header justify-content-center">
      <h3 class="font-weight-light my-4">Select Your Profile</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <!-- <div
          class="col-12 profile text-center"
          @click.prevent="$router.push('/customer/buymarine')"
        >
          <div class="circular d-block mx-auto indi"></div>
          <p class="text-center py-2">Individual / Corporate</p>
        </div> -->

        <div
          class="col-6 profile text-center"
          @click.prevent="$emit('setRole', 'Broker')"
        >
          <div class="circular d-block mx-auto broker"></div>
          <p class="text-center py-2">Broker</p>
        </div>

        <div
          class="col-6 profile text-center"
          @click.prevent="$emit('setRole', 'Agent')"
        >
          <div class="circular d-block mx-auto agent"></div>
          <p class="text-center py-2">Agent</p>
        </div>
      </div>
    </div>
    <!-- <div class="card-footer text-center">
                <div class="small">
                  <router-link to="#">Need an account? Sign up!</router-link>
                </div>
              </div> -->
  </div>
</template>

<script>
// import { $Session } from "../helpers/storage";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      data: {
        email: "",
        password: "",
        role: "",
      },
    };
  },

  created() {
    // $Session.clear();
  },
};
</script>

<style>
.circular {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 2px #991f36 solid;
}

.indi {
  background-image: url("../assets/individual.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 110%;
}
.broker {
  background-image: url("../assets/broker.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 110%;
}
.agent {
  background-image: url("../assets/agent.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 110%;
}

.profile:hover {
  scale: 1.02;
}

.profile {
  cursor: pointer;
  transition: ease-in-out;
  transition-duration: 0.2s;
}
</style>
